@font-face {
    font-family: 'Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    src: url("fonts/Gilroy-Regular.ttf");
}

@font-face {
    font-family: 'Bold';
    font-weight: bold;
    font-size: 42px;
    line-height: 150%;
    font-style: normal;
    src: url("fonts/Gilroy-Bold.ttf");
}

@font-face {
    font-family: 'SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    src: url("fonts/Gilroy-SemiBold.ttf");
}

iframe {
    display: none;
}
