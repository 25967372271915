.fade-in-section {
    opacity: 0;
    transform: translateY(50px); /* Initial position off-screen */
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: translateY(0); /* Slide up to final position */
}

.slide-up-section {
    width: 100%;
    opacity: 0;
}

.slide-up-section.is-visible {
    opacity: 1;
}

.slide-up-section {
    transition-timing-function: ease-in-out; /* Users smoothness to the animation */
}
