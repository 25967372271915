@import "./stylesheet.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~video-react/dist/video-react.css";
@import "~swiper/swiper-bundle.css";

:root {
    --regular: 'Regular';
    --bold: 'Bold';
    --semi-bold: 'SemiBold'
}

.client-item {
    max-width: 250px;
    width: 148px;
    height: 42px;
    opacity: 0.6;
}

html {
    overflow: hidden scroll;
}

body {
    font-family: var(--regular);
    padding: 0;
    margin: 0;
    overflow: hidden scroll;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

body::-webkit-scrollbar {
    width: 0;
}

input::placeholder {
    transition: 0.5s;
}

::-webkit-scrollbar-button {
    height: 0;
}

::-webkit-scrollbar-track {
    background: #bebebe
}

::-webkit-scrollbar-track-piece {
    background: #bebebe
}

::-webkit-scrollbar-thumb {
    background: #6c6c6c;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.row > * {
    padding: 0 !important;
    margin: 0 !important;
}

.slick-dots {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick-dots li.slick-active button:before {
    content: '';
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background: #2684FF;
}

.swiper-slide {
    min-width: 374px;
}

.row > * {
    padding: 0 !important;
    margin: 0 !important;
}

.modal-active {
    overflow: hidden;
}

@media only screen and (max-width: 992px) {
    .full-width {
        width: 100% !important;
        margin: auto !important;
    }
}

a {
    text-decoration: none !important;
    cursor: pointer;
}

.slick-slider *{
    outline: none !important;
}

.rfm-initial-child-container {
    align-items: center;
}

.scrolled {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 32px;
    border-radius: 0px 0px 16px 16px;
    background: #fff;
    padding: 15px 20px 15px 20px !important;
}

img {
    width: 100%;
}
